.header-container {
    display: flex;
    justify-content: center;
}

.header-container ul {
    display: flex;
    font-size: 1.2rem;
    margin: 3rem 0;
}

.header-container ul li {
    margin: 0 2rem;
    padding: 10px 20px;
    font-weight: 400;
    color: #fff;
    transition: .5s;
    border: 0px solid #fff;
    cursor: pointer;
    border-radius: 10px;
}

.header-container ul li:hover {
    background-color: #fff;
    color: #91703D;
}

.animed-bg {
    transition: background .3s ease;
}