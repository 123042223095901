.App {
  font-family: 'Ubuntu', sans-serif;
  color: #fff;
  flex-direction: column;
  display: flex;
  align-items: center;
  background: #1D1D1D;
  width: 100%;
  height: 100vh;
}

@font-face {
  font-family: "Rebelion";
  src: url("./fonts/Rebellion.woff") format("woff2"),
        url("./fonts/Rebellion.woff2") format("woff");
 }

.App-Logo {
  margin-top: 4rem;
  width: 200px;
  height: 200px;
}

html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
  color: #fff;
  text-decoration: underline;
}

.App-container {
  background: url('./images/bg-milieu.jpeg');
  position: absolute;
  width: 100%;
  height: 100%;
}

.App-wrapper {
  flex-direction: column;
  align-items: center;
  display: flex;
  width: 100%;
  height: 100vh;
  background: rgba(29, 29, 29, 0.5);
}

.App-wrapper2 {
  flex-direction: column;
  align-items: center;
  display: flex;
  width: 100%;
  background: rgba(29, 29, 29, 1);
}

.app-banner {
  background: url('./images/bg-milieuP.jpeg');
  display: flex;
  width: 100%;
  height: 250px;
}